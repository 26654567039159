<template>
  <div>
    <div class="img1">
      <div class="h1">文章修改+降重</div>
      <div class="img_tip">文章润色，格式排版，专业降重</div>
    </div>
    <div class="contain">
      <div class="h1">
        <div class="h1_top">
          <img src="@/assets/image/icon67.png" alt="" />
          <div>服务介绍</div>
          <img src="@/assets/image/icon68.png" alt="" />
        </div>
        <img src="@/assets/image/icon69.png" alt="" class="h1_bottom" />
      </div>
      <div class="list">
        <div class="list_item1">
          <img src="@/assets/image/icon73.png" alt="" class="img1_1" />
          <div class="box">
            <div>格式排版服务</div>
            <div>
              完全遵照客户格式要求采用人工方式进行格式修改，针对封页、页眉页脚、页码、字体、行间距等具体内容进行修改，同时附赠文章《致谢》内容，此外还针对文章内容和毕业疑难问题免费指导，
              效率高、速度快、价格低，以最好的服务，达到让您满意的效果
            </div>
            <div>格式排版服务介绍</div>
          </div>
        </div>
        <div class="list_item2">
          <img src="@/assets/image/icon74.png" alt="" class="img1_1" />
          <div class="box">
            <div>文章润色服务</div>
            <div>
              全科专业老师一对一服务，遵循老师意见进行文章内容修改。
              效率高速度快服务可分为单次修改（一次性将目前所有意见修改完毕）和多次修改（修改到老师通过为止）收费标准根据专业难度，内容深度，时间要求来确定
            </div>
            <div>文章润色服务介绍</div>
          </div>
        </div>
        <div class="list_item1">
          <img src="@/assets/image/icon72.png" alt="" class="img1_1" />
          <div class="box">
            <div>专业降重服务</div>
            <div>
              根据学校要求按照查重报告进行一对一降重服务，查重报告尽量选取知网，维普，格子达等权威性较高查重网站，依据查重报告重复率进行收费，中间无二次收费。友情提示：同学应尽量选择与学校查重系统一致的权威网站，或直接用学校查重系统进行查重
            </div>
            <div>专业降重服务介绍</div>
          </div>
        </div>
      </div>
      <div class="btn" @click="show = !show">
        <img src="@/assets/image/icon70.png" alt="" />
        联系客服
        <div style="position: absolute;left: 380px;">
          <telToast :show="show" v-if="show" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import telToast from "@/components/telToast.vue";
export default {
  components: {
    telToast,
  },
  data () {
    return {
      show: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.img1 {
  width: 100%;
  height: 500px;
  background: url("../../assets/image/icon71.png");
  background-size: 100% 500px;
  background-repeat: no-repeat;
  padding: 10px 0 0;
  box-sizing: border-box;
  .h1 {
    font-size: 99px;
    font-family: zihunxinquhei, zihunxinquhei-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    letter-spacing: 4.46px;
    text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
  }
  .img_tip {
    margin: 6px auto 0;
    width: 580px;
    height: 54px;
    background: url("../../assets/image/icon101.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 24px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: zihunxinquhei, zihunxinquhei-Regular;
    font-weight: 400;
    text-align: center;
    color: #23dc5a;
    letter-spacing: 1.62px;
    text-shadow: 0px 3px 6px 0px rgba(159, 214, 253, 0.3);
  }
}
.contain {
  width: 1400px;
  // height: 848px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto 50px;
  padding: 40px 20px 50px;
  box-sizing: border-box;
  .h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .h1_top {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      div {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #007938;
        letter-spacing: 1.08px;
        margin: 0 20px;
      }

      img {
        width: 19px;
        height: 19px;
      }
    }
    .h1_bottom {
      width: 156px;
      height: 5px;
    }
  }

  .btn {
    width: 366px;
    height: 65px;
    background: linear-gradient(90deg, #23c48c, #33da61);
    border-radius: 6px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 0.9px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 97px auto 0;
    cursor: pointer;
  position: relative;
    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .list {
    margin-top: 40px;

    .list_item1 {
      width: 1360px;
      height: 300px;
      position: relative;
      padding-top: 53px;
      box-sizing: border-box;
      margin-bottom: 40px;
      background: linear-gradient(
        220deg,
        #33da61 -60%,
        #ffffff 16%,
        #ffffff 10%
      );
      box-shadow: 0px 3px 10px 0px rgba(235, 235, 235, 0.5);
      .img1_1 {
        width: 400px;
        height: 300px;
        position: absolute;
        left: 20px;
        top: -20px;
      }
      .box {
        margin-left: 470px;
        div:nth-child(1) {
          font-size: 22px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #333333;
          margin: 0 0 20px 0;
        }
        div:nth-child(2) {
          width: 840px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 20px;
        }
        div:nth-child(3) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
        }
      }
    }
    .list_item2 {
      position: relative;
      margin-bottom: 40px;
      padding-top: 53px;
      box-sizing: border-box;
      width: 1360px;
      height: 300px;
      background: linear-gradient(
        140deg,
        #33da61 -60%,
        #ffffff 16%,
        #ffffff 10%
      );
      box-shadow: 0px 3px 10px 0px rgba(235, 235, 235, 0.5);
      .img1_1 {
        width: 400px;
        height: 300px;
        position: absolute;
        right: 20px;
        top: -20px;
      }
      .box {
        margin-right: 470px;
        padding-left: 50px;
        div:nth-child(1) {
          font-size: 22px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          text-align: right;
          color: #333333;
          margin: 0 0 20px 0;
        }
        div:nth-child(2) {
          // width: 900px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: right;
          color: #333333;
          margin-bottom: 20px;
        }
        div:nth-child(3) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: right;
          color: #999999;
        }
      }
    }
  }
}
</style>